<template>
  <Layout />
</template>

<script>
import Layout from '@mobile/common/Layout'
export default {
  mounted() {
    window.localStorage.setItem('wxShareUrl', location.href)
  },
  components: {
    Layout,
  },
}
</script>
