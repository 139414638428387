<template>
  <div class="pc-container">
    <PageHeader />
    <nuxt class="pc-content" />
    <PageFooter class="pc-footer" />
  </div>
</template>

<script>
import PageHeader from '@pc/common/PageHeader'
import PageFooter from '@pc/common/PageFooter'

export default {
  data() {
    return {
      headNoSpace: true,
    }
  },
  components: {
    PageHeader,
    PageFooter,
  },
}
</script>

<style lang="scss" scoped>

.pc-container {
  flex-direction: column;
  justify-content: space-between;
  min-width: 1280px;
  min-height: 100vh;
}

.pc-content {
  min-height: calc(100vh - 349px);
}

.__nuxt-error-page {
  position: relative;
}

</style>
