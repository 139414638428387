<template>
  <div class="dd-header-outer">
    <div class="dd-header dd-container">
      <nuxt-link class="flex items-center" to="/" @click.native="handleHomePage">
        <h1 class="dd-logo"></h1>
        <!-- <p class="title ml-8px">伟润科技</p> -->
      </nuxt-link>

      <div class="nav-bar h-full flex ml-26px">
        <div class="nav-item">
          <nuxt-link exact to="/">首页</nuxt-link>
        </div>
        <div class="nav-item">
          <nuxt-link to="/case">案例展示</nuxt-link>
        </div>
        <div class="nav-item">
          <nuxt-link exact to="/about">关于我们</nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleHomePage() {
      if (this.$route.path === '/') {
        this.$router.go(0)
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.dd-header-outer {
  width: 100%;
  border-bottom: 1px solid rgba(97, 81, 102, 0.1);
  box-shadow: 2px 0 8px rgba(97, 81, 102, 0.1);
}
.dd-header {
  display: flex;
  box-sizing: border-box;
  height: 99px;
  background: $main-bg-color;

  .dd-logo {
    display: block;
    width: 149px;
    height: 33px;
    background: url('~assets/img/common/logo-default.png') center/100% no-repeat;
  }

  .title {
    color: $main-text-color;
    letter-spacing: 3px;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
  }

  .nav-bar {
    .nav-item {
      margin-left: 38px;
      height: 100%;
      text-align: center;
      cursor: pointer;

      a {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 74px;
        height: 100%;
        color: $main-text-color;
        font-weight: 400;
        font-size: 16px;

        &.nuxt-link-exact-active,
        &.nuxt-link-active {
          color: $main-text-active-color;
          text-align: center;
          font-weight: bold;
          opacity: 1;

          &::after {
            position: absolute;
            bottom: 0;
            width: 74px;
            height: 8px;
            background-color: $main-text-active-color;
            content: ' ';
          }
        }
      }
    }

    .lang {
      display: flex;
      align-items: center;
      margin-left: 80px;

      a {
        color: #000000;
        font-weight: 200;
        font-size: 16px;
        // font-family: PingFangSC-Regular, PingFang SC;
        line-height: 20px;

        &.nuxt-link-exact-active,
        &.nuxt-link-active {
          font-weight: bold;
          // font-family: PingFangSC-Semibold, PingFang SC;
          opacity: 1;
        }
      }

      .vline {
        margin: 0 10px;
      }
    }
  }
}

</style>
