// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/common/logo-default.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/img/common/heng.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dd-header[data-v-1da9240c]{position:relative;z-index:999;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;padding:0 5.333vw;width:100vw;height:13.333vw;background:#fff}.dd-header[data-v-1da9240c],.dd-header .dd-logo-container[data-v-1da9240c]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.dd-header .dd-logo[data-v-1da9240c]{display:inline-block;overflow:hidden;margin-right:1.6vw;width:26.4vw;height:5.867vw;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50%/100% no-repeat}.dd-header .title[data-v-1da9240c]{color:#333;letter-spacing:.533vw;font-weight:800;font-size:3.467vw;line-height:5.067vw}.dd-header .menu[data-v-1da9240c]{display:inline-block;width:4vw;height:3.733vw;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") 50%/contain no-repeat;cursor:pointer}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
