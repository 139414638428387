import homeApi from '~/api/home'

export const state = () => ({
  bannerList: []
})

export const mutations = {
  SET_HOME (state, data) {
    state.bannerList = data.bannerList
  }
}

export const actions = {
  async getHomeData ({ commit }, app) {
    const res = await homeApi.getHomepage()

    if (res.ok) {
      commit('SET_HOME', {
        bannerList: res.data
      })
    }
  }
}
