import Api from '@/api/about'

export const state = () => ({
  detail: {}
})

export const mutations = {
  SET_DETAIL (state, data) {
    state.detail = data
  }
}

export const actions = {
  async getDetail ({ commit }, { app }) {
    const res = await Api.getDetail({
      lang: app.i18n.getLocaleCookie()
    })

    if (res.ok) {
      const { content } = res.data
      // 实现图片懒加载
      if (content) {
        res.data.content = content.replace(/<img(.*?)(src)="(.*?)"(.*?)>/gi, '<img$1data-src="$3"$4>')
      }

      commit('SET_DETAIL', res.data)
    }
  }
}
