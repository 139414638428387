import Base from '../Base'
class Home extends Base {
  constructor () {
    super()
    this.baseURL = process.env.API_BASE_URL
    this.getHomepageUrl = '/api/banner/list'
    this.feedbackUrl = '/api/feedback/add'
  }

  // 获取官网首页banner及展讯
  getHomepage (params) {
    return this.sendGet(this.getHomepageUrl, params)
  }

  feedback (params) {
    return this.sendPost(this.feedbackUrl, params)
  }
}
export default new Home()
