<template>
  <Layout />
</template>

<script>
import Layout from '@pc/common/EmptyLayout'

export default {
  components: {
    Layout
  }
}
</script>
