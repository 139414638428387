import Api from '@/api/case'

export const state = () => ({
  current: 1, // 当前分页页数
  pages: 1, // 默认总页数为 1 页
  list: [],
  detail: {}
})

export const mutations = {
  SET_LIST (state, data) {
    state.list = data.list
    state.pages = data.pages
    state.current = data.current
  },

  SET_DETAIL (state, data) {
    state.detail = data
  }
}

export const actions = {
  /**
   * @param {Boolean} isServerRender 是否是服务端渲染
   * @desc typeof isSubPageGoBack === 'boolean' && isSubPageGoBack 表示通过浏览器返回且从咨询详情页返回的情况
   */
  async getList ({ commit, state }, { isServerRender, isSubPageGoBack }) {
    try {
      const isInitData = isServerRender || (typeof isSubPageGoBack === 'boolean' && !isSubPageGoBack)
      let currentPage = isInitData ? 1 : state.current

      if (currentPage > state.pages) {
        return
      }

      const res = await Api.getList({
        current: currentPage,
        size: 10
      })

      currentPage++

      if (res.ok) {
        let { pages, records } = res.data

        if (!isInitData) {
          records = state.list.concat(records)
        }

        commit('SET_LIST', {
          list: records,
          pages,
          current: currentPage
        })
      }
    } catch (err) {
      console.error(err)
    }
  },

  async getDetail ({ commit }, { id }) {
    const res = await Api.getDetail({
      id
    })

    if (res.ok) {
      console.log(res)
      const { exhibit, briefIntroduction } = res.data
      // 实现图片懒加载
      if (exhibit) {
        res.data.exhibit = exhibit.replace(/<img(.*?)(src)="(.*?)"(.*?)>/gi, '<img$1data-src="$3"$4src="$3">')
      }

      if (briefIntroduction) {
        res.data.briefIntroduction = briefIntroduction.replace(/<img(.*?)(src)="(.*?)"(.*?)>/gi, '<img$1data-src="$3"$4src="$3">')
      }

      commit('SET_DETAIL', res.data)
    }
  }
}
