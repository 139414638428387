import Vue from 'vue'
import axios from 'axios'
import wx from 'weixin-js-sdk'

Vue.prototype.$axios = axios
Vue.prototype.$wechat = wx
function getIsWxClient () {
  var ua = navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true
  }
  return false
}

const wechatShare = {
  install (Vue) {
    Vue.prototype.wxShare = function (shareData, url) {
      if (!getIsWxClient()) {
        return
      }

      this.$axios
        .get('https://api.weiruntech.com/api/wechat/config', {
          // 请求配置
          params: {
            url: decodeURIComponent(url)
          }
        })
        .then(res => {
          const { appId, timestamp, noncestr, signature } = res.data.data
          this.$wechat.config({
            debug: false,
            appId,
            timestamp,
            nonceStr: noncestr,
            signature,
            jsApiList: [
              'checkJsApi',
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'updateAppMessageShareData',
              'updateTimelineShareData'
            ]
          })
        })
      this.$wechat.ready(() => {
        // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
        this.$wechat.updateAppMessageShareData({
          title: shareData.title,
          desc: shareData.desc,
          link: shareData.url,
          imgUrl: shareData.imgUrl,
          success: function () {
            // 设置成功
          },
          cancel: function () {
            console.log('分享取消')
          }
        })
        this.$wechat.onMenuShareAppMessage({
          title: shareData.title,
          desc: shareData.desc,
          link: shareData.url,
          imgUrl: shareData.imgUrl,
          success: function () {
            // 设置成功
          },
          cancel: function () {
            console.log('分享取消')
          }
        })
        // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
        this.$wechat.onMenuShareTimeline({
          title: shareData.title,
          link: shareData.url,
          imgUrl: shareData.imgUrl,
          success: function () {
            // 设置成功
          },
          cancel: function () {
            console.log('分享取消')
          }
        })
        this.$wechat.updateTimelineShareData({
          title: shareData.title,
          link: shareData.url,
          imgUrl: shareData.imgUrl,
          success: function () {
            // 设置成功
          },
          cancel: function () {
            console.log('分享取消')
          }
        })
      })
    }
  }
}

Vue.use(wechatShare)
