import axios from 'axios'
import { Toast } from 'vant'

// 创建axios实例
const api = axios.create({
  validateStatus: function (status) {
    return status >= 200 && status < 300 // 默认的
  }
})

function getLang (lang) {
  // EN CH JP
  switch (lang) {
    case 'zh':
      return 'CH'
    case 'en':
      return 'EN'
    default:
      return 'JP'
  }
}

api.interceptors.request.use(config => {
  // const { params, data } = config

  // if ((params && params.lang)) {
  //   config.headers.language = getLang(params.lang)
  //   delete params.lang
  // }

  // if ((data && data.lang)) {
  //   config.headers.language = getLang(data.lang)
  //   delete data.lang
  // }

  return config
})

// Add a response interceptor
api.interceptors.response.use(
  function (response) {
    const { code, msg } = response.data
    response.data.ok = code >= 200 && code < 300

    if (!response.data.ok) {
      Toast.fail(msg)
    }

    return response.data
  },
  function (error) {
    error.request && error.request.readyState === 4 && console.error(JSON.stringify(error))
    Toast.fail('接口异常')
    return Promise.reject(error)
  }
)

export default api
