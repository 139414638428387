<template>
  <div class="dd-header" @touchmove.prevent>
    <nuxt-link class="dd-logo-container" to="/mobile">
      <h1 class="dd-logo"></h1>
      <!-- <p class="title">伟润科技</p> -->
    </nuxt-link>

    <div v-show="!showNavBar">
      <span class="menu" @click="toggleMenu"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showNavBar: Boolean,
  },
  methods: {
    toggleMenu() {
      this.$emit('update:showNavBar', !this.showNavBar)
    },
  },
}
</script>

<style lang="scss" scoped>

.dd-header {
  position: relative;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  width: 100vw;
  height: 50px;
  background: #fff;

  .dd-logo-container {
    display: flex;
    align-items: center;
  }

  .dd-logo {
    display: inline-block;
    overflow: hidden;
    margin-right: 6px;
    width: 99px;
    height: 22px;
    background: url('~assets/img/common/logo-default.png') center/100% no-repeat;
  }

  .title {
    color: #333333;
    letter-spacing: 2px;
    font-weight: 800;
    font-size: 13px;
    line-height: 19px;
  }

  .menu {
    display: inline-block;
    width: 15px;
    height: 14px;
    background: url('~assets/img/common/heng.jpg') center/contain no-repeat;
    cursor: pointer;
  }
}

</style>
