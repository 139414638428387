import { pcOrMobile } from '../assets/js/util.js'

export default function ({ req, app, store, route, params, error, redirect }) {
  if (process.server) {
    const path = route.fullPath

    // pc 和 h5 重定向
    const matchArr = path.match(/^(\/zh|\/en)?\/mobile(\/?.*)$/i)
    const urlIsMobile = !!matchArr
    const agentIsMobile = pcOrMobile(req.headers['user-agent'])

    if (agentIsMobile && !urlIsMobile) {
      return redirect(`${path.replace(/^(\/zh|\/en)?/i, '$1/mobile')}`)
    } else if (!agentIsMobile && urlIsMobile) {
      let str = ''
      if (matchArr && matchArr.length && !matchArr[2]) {
        str = '/'
      }
      return redirect(`${path.replace(/^(\/zh|\/en)?\/mobile(\/?.*)$/i, `$1$2${str}`)}`)
    }
  }
}
