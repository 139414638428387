<template>
  <div class="nav" @touchmove.prevent>
    <svg class="icon close" :class="{ ani: activeAnimation }" aria-hidden="true" @click="() => handleClose(true)">
      <use xlink:href="#icon-guanbi"></use>
    </svg>

    <div class="nav-main">
      <nuxt-link exact to="/mobile">
        <div class="nav-main-item" @click.stop="() => handleClose()">首页</div>
      </nuxt-link>

      <nuxt-link to="/mobile/case">
        <div class="nav-main-item" @click.stop="() => handleClose()">案例介绍</div>
      </nuxt-link>

      <nuxt-link to="/mobile/about">
        <div class="nav-main-item" @click.stop="() => handleClose()">关于我们</div>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeAnimation: false,
    }
  },
  props: {
    showNavBar: Boolean,
  },
  methods: {
    handleClose(animation) {
      if (animation) {
        this.activeAnimation = animation

        setTimeout(() => {
          this.$emit('update:showNavBar', false)
          this.activeAnimation = false
        }, 500)
      } else {
        this.activeAnimation = false
        this.$emit('update:showNavBar', false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.nav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  overflow: auto;
  box-sizing: border-box;
  padding-top: 140px;
  width: 100vw;
  height: 100vh;
  background: #fff;

  .close {
    position: absolute;
    top: 26px;
    right: 22px;
    z-index: 10000;
    width: 16px;
    height: 16px;
    color: #000000;
  }

  .ani {
    animation: rotate90 0.3s forwards;
  }

  @keyframes rotate90 {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(90deg);
    }
  }

  &-main {
    flex: 1;

    a {
      display: flex;
      justify-content: center;
    }

    &-item {
      box-sizing: border-box;
      margin-bottom: 5px;
      padding: 24px 0;
      width: 227px;
      border-bottom: 1px solid #eeeeee;
      color: #000;
      text-align: center;
      font-size: 18px;
      line-height: 43px;
    }

    ::v-deep .nuxt-link-exact-active,
    ::v-deep .nuxt-link-active {
      .nav-main-item {
        font-weight: bold;
      }
    }
  }
}

</style>
