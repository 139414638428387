import Base from '../Base'
class Home extends Base {
  constructor () {
    super()
    this.baseURL = process.env.API_BASE_URL
    this.getListUrl = '/api/news/list'
    this.getDetailUrl = '/api/news/details'
  }

  getList (params) {
    return this.sendGet(this.getListUrl, params)
  }

  getDetail (params) {
    return this.sendGet(this.getDetailUrl, params)
  }
}
export default new Home()
